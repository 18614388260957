import { default as indexDE4ZDREdqBMeta } from "/tmp/build_18280244/pages/connections/index.vue?macro=true";
import { default as indexgBlVtzX66YMeta } from "/tmp/build_18280244/pages/delete-bin/index.vue?macro=true";
import { default as _91share_token_93xvTmzOXnahMeta } from "/tmp/build_18280244/pages/external/embed/[user_id]/[file_id]/[share_token].vue?macro=true";
import { default as _91file_id_93cOkQ6N1sloMeta } from "/tmp/build_18280244/pages/gallery/[file_id].vue?macro=true";
import { default as galleryaT6Ul8lTgJMeta } from "/tmp/build_18280244/pages/gallery.vue?macro=true";
import { default as indexx6sc6kvG03Meta } from "/tmp/build_18280244/pages/groups/index.vue?macro=true";
import { default as _91_91id_93_938hOHOCT0EAMeta } from "/tmp/build_18280244/pages/impersonate/[[id]].vue?macro=true";
import { default as log_45inJWXMDoT4C0Meta } from "/tmp/build_18280244/pages/log-in.vue?macro=true";
import { default as log_45outvSVlq2JFCsMeta } from "/tmp/build_18280244/pages/log-out.vue?macro=true";
import { default as indexl55Lah3WF2Meta } from "/tmp/build_18280244/pages/notifications/index.vue?macro=true";
import { default as projectsEaDtPz8DxpMeta } from "/tmp/build_18280244/pages/projects.vue?macro=true";
import { default as _91file_id_937qkNdXvBnzMeta } from "/tmp/build_18280244/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93oFwWbK9KFiMeta } from "/tmp/build_18280244/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexi6rah1U4swMeta } from "/tmp/build_18280244/pages/users/[user_id_or_slug]/albums/index.vue?macro=true";
import { default as visitedZv9fklqYuhMeta } from "/tmp/build_18280244/pages/users/[user_id_or_slug]/albums/visited.vue?macro=true";
import { default as _91file_id_93iLIy8kBwObMeta } from "/tmp/build_18280244/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93xwAl2vDy7AMeta } from "/tmp/build_18280244/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue?macro=true";
import { default as _91_91share_token_93_93mXz5RxXitFMeta } from "/tmp/build_18280244/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue?macro=true";
import { default as indexeK3Q63ZwXKMeta } from "/tmp/build_18280244/pages/users/[user_id_or_slug]/index.vue?macro=true";
import { default as _91file_id_9303stQAjiYYMeta } from "/tmp/build_18280244/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_938l2IGDaJGTMeta } from "/tmp/build_18280244/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue?macro=true";
import { default as index5e9CMVceI4Meta } from "/tmp/build_18280244/pages/users/[user_id_or_slug]/people/index.vue?macro=true";
import { default as _91file_id_93GqnSnU8ftrMeta } from "/tmp/build_18280244/pages/users/[user_id_or_slug]/print-projects/[file_id].vue?macro=true";
import { default as print_45projectsErCe0l0V6hMeta } from "/tmp/build_18280244/pages/users/[user_id_or_slug]/print-projects.vue?macro=true";
import { default as _91file_id_93bbdjxokI8vMeta } from "/tmp/build_18280244/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_937x7Ia8gMG2Meta } from "/tmp/build_18280244/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexB1H1BKs7rMMeta } from "/tmp/build_18280244/pages/users/[user_id_or_slug]/tags/index.vue?macro=true";
import { default as welcomehtTcUX042UMeta } from "/tmp/build_18280244/pages/welcome.vue?macro=true";
import { default as component_45stubODblM1yXAnMeta } from "/tmp/build_18280244/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubODblM1yXAn } from "/tmp/build_18280244/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "connections",
    path: "/connections",
    meta: indexDE4ZDREdqBMeta || {},
    component: () => import("/tmp/build_18280244/pages/connections/index.vue")
  },
  {
    name: "delete-bin",
    path: "/delete-bin",
    meta: indexgBlVtzX66YMeta || {},
    component: () => import("/tmp/build_18280244/pages/delete-bin/index.vue")
  },
  {
    name: "external-embed-user_id-file_id-share_token",
    path: "/external/embed/:user_id()/:file_id()/:share_token()",
    component: () => import("/tmp/build_18280244/pages/external/embed/[user_id]/[file_id]/[share_token].vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    meta: galleryaT6Ul8lTgJMeta || {},
    component: () => import("/tmp/build_18280244/pages/gallery.vue"),
    children: [
  {
    name: "gallery-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_18280244/pages/gallery/[file_id].vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    meta: indexx6sc6kvG03Meta || {},
    component: () => import("/tmp/build_18280244/pages/groups/index.vue")
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id?",
    component: () => import("/tmp/build_18280244/pages/impersonate/[[id]].vue")
  },
  {
    name: "log-in",
    path: "/log-in",
    component: () => import("/tmp/build_18280244/pages/log-in.vue")
  },
  {
    name: "log-out",
    path: "/log-out",
    component: () => import("/tmp/build_18280244/pages/log-out.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: indexl55Lah3WF2Meta || {},
    component: () => import("/tmp/build_18280244/pages/notifications/index.vue")
  },
  {
    name: "projects",
    path: "/projects",
    meta: projectsEaDtPz8DxpMeta || {},
    component: () => import("/tmp/build_18280244/pages/projects.vue")
  },
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/albums/:album_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_18280244/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_18280244/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-albums",
    path: "/users/:user_id_or_slug()/albums",
    meta: indexi6rah1U4swMeta || {},
    component: () => import("/tmp/build_18280244/pages/users/[user_id_or_slug]/albums/index.vue")
  },
  {
    name: "users-user_id_or_slug-albums-visited",
    path: "/users/:user_id_or_slug()/albums/visited",
    meta: visitedZv9fklqYuhMeta || {},
    component: () => import("/tmp/build_18280244/pages/users/[user_id_or_slug]/albums/visited.vue")
  },
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/containers/:container_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_18280244/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_18280244/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-files-file_id-share_token",
    path: "/users/:user_id_or_slug()/files/:file_id()/:share_token?",
    component: () => import("/tmp/build_18280244/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue")
  },
  {
    name: "users-user_id_or_slug",
    path: "/users/:user_id_or_slug()",
    component: () => import("/tmp/build_18280244/pages/users/[user_id_or_slug]/index.vue")
  },
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/people/:person_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_18280244/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_18280244/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-people",
    path: "/users/:user_id_or_slug()/people",
    meta: index5e9CMVceI4Meta || {},
    component: () => import("/tmp/build_18280244/pages/users/[user_id_or_slug]/people/index.vue")
  },
  {
    name: "users-user_id_or_slug-print-projects",
    path: "/users/:user_id_or_slug()/print-projects",
    meta: print_45projectsErCe0l0V6hMeta || {},
    component: () => import("/tmp/build_18280244/pages/users/[user_id_or_slug]/print-projects.vue"),
    children: [
  {
    name: "users-user_id_or_slug-print-projects-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_18280244/pages/users/[user_id_or_slug]/print-projects/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/tags/:tag_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_18280244/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_18280244/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags",
    path: "/users/:user_id_or_slug()/tags",
    meta: indexB1H1BKs7rMMeta || {},
    component: () => import("/tmp/build_18280244/pages/users/[user_id_or_slug]/tags/index.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomehtTcUX042UMeta || {},
    component: () => import("/tmp/build_18280244/pages/welcome.vue")
  },
  {
    name: component_45stubODblM1yXAnMeta?.name,
    path: "/",
    component: component_45stubODblM1yXAn
  },
  {
    name: component_45stubODblM1yXAnMeta?.name,
    path: "/library",
    component: component_45stubODblM1yXAn
  },
  {
    name: component_45stubODblM1yXAnMeta?.name,
    path: "/static/images/forever-album-no-cover.png",
    component: component_45stubODblM1yXAn
  }
]